import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import TableToExcel from "@linways/table-to-excel";

import "./ClinicalSafetySummary.css";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import Button from "../../Button";

const ClinicalSafetySummary = ({ summary }) => {
  const [downloadingXLS, setDownloadingXLS] = useState(false);

  useEffect(() => {
    if (downloadingXLS) {
      TableToExcel.convert(document.getElementById("clinical-safety-table"));
      setDownloadingXLS(false);
    }
  }, [downloadingXLS]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        {summary?.HAZID_Summary && (
          <div className="bg-slate-50 rounded-lg">
            <div className="p-8 border-b border-slate-200">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 sm:text-xl">
                HAZID <span className="text-base">(Hazard Identification)</span>
              </h2>
            </div>
            <div className="p-8">
              <ul className="space-y-4">
                {summary.HAZID_Summary.map((summaryItem) => (
                  <li>{summaryItem}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {summary?.SWIFT_Summary && (
          <div className="bg-slate-50 rounded-lg">
            <div className="p-8 border-b border-slate-200">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 sm:text-xl">
                SWIFT{" "}
                <span className="text-base">
                  (Structured What-If Technique)
                </span>
              </h2>
            </div>
            <div className="p-8">
              <ul className="space-y-4">
                {summary.SWIFT_Summary.map((summaryItem) => (
                  <li>{summaryItem}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {summary?.FFA_Summary && (
          <div className="bg-slate-50 rounded-lg">
            <div className="p-8 border-b border-slate-200">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 sm:text-xl">
                FFA{" "}
                <span className="text-base">(Functional Failure Analysis)</span>
              </h2>
            </div>
            <div className="p-8">
              <ul className="space-y-4">
                {summary.FFA_Summary.map((summaryItem) => (
                  <li>{summaryItem}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>

      {summary?.Hazard_Table && (
        <div className="bg-slate-50 rounded-lg mt-10">
          <div className="p-8 border-b border-slate-200">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 sm:text-xl">
                Hazard Log
              </h2>
              <Button
                color="white"
                onClick={async () => {
                  setDownloadingXLS(true);
                }}
              >
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-1"
                  aria-hidden="true"
                />
                Download as XLS
              </Button>
            </div>
          </div>
          <div className="p-8">
            <div className="rounded-lg overflow-hidden shadow">
              <div className="hazard-table overflow-auto">
                <Markdown id="clinical-safety-table" className="mt-4" data-cols-width="10,20,50,50,50,50,50,50,50,20,20">
                  {summary?.Hazard_Table}
                </Markdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClinicalSafetySummary;
